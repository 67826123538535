import React, { Fragment, useRef, useLayoutEffect, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu as HuiMenu, Transition as HuiTransition } from '@headlessui/react'
import { useClassNames } from '../../hooks/useClassNames';

export const MenuButton = ({children, className, ...props}) => {
  const classNames = useClassNames();

  return (
    <div>
      {/* <Menu.Button className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"> */}
      <HuiMenu.Button className={(className ? `${className} ` : "-my-2 p-2 rounded-full flex items-center focus:outline-none ") + "text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-200"} {...props}>
          {children}
      </HuiMenu.Button>
    </div>
  );
}

export const MenuItem = ({index, action}) => {
  const classNames = useClassNames();

  //const id = `action-${index}`;
  const id = action.id || `action-${index}`;

  return (
    <HuiMenu.Item key={index}>
      {({ active }) => (
        action.href ?
        <NavLink 
          id={id}
          key={id}
          to={action.href}
          className={classNames.classNames(
          active ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-200' : 'text-gray-700 dark:text-gray-300',
          'w-full flex justify-between px-4 py-2 text-sm')}
        >
          {action.name}
        </NavLink>
        :
        <button
          id={id}
          key={id}
          type="button"
          className={classNames.classNames(
              active ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-200' : 'text-gray-700 dark:text-gray-300',
              'w-full flex justify-between px-4 py-2 text-sm'
          )}
          disabled={action.disabled}
          onClick={action.onClick}
          to={action.href}
        >
          {action.name}
        </button>
      )}
    </HuiMenu.Item>
  );
}

export const Menu = ({as = "div", buttonElement, actions, refContainer, ...props}) => {
  const classNames = useClassNames();

  const refMenu = useRef();
  const refPopup = useRef();
  const [popupOffset, setPopupOffset] = useState({x: 0, y: 0});

  useEffect(() => {
  }, []);

  const onBeforeEnter = () => {
    if (refPopup.current && refContainer?.current && refMenu.current) {
      const popupRect = refPopup.current.getBoundingClientRect();
      const containerRect = refContainer.current.getBoundingClientRect();
      const menuRect = refMenu.current.getBoundingClientRect();

      const styles = getComputedStyle(refPopup.current);
      let x = parseInt(styles.right.slice(0, styles.right.length - 2));
      let y = parseInt(styles.top.slice(0, styles.top.length - 2));

      console.log("Menu", "onBeforeEnter",
        "containerRect.height", containerRect.height, 
        "popupRect.height", popupRect.height,
        "x", x, "y", y
      );

      const pb = popupRect.bottom + y;

      const popUpBorder = 12;
      if (pb > containerRect.bottom) {
        y -= (pb - containerRect.bottom) + popUpBorder;

        const pt = popupRect.top + y;
        if (pt < containerRect.top)
        {
          const diff = pt - containerRect.top;
          y -= (diff / 2);
        }
      }
      x = menuRect.width - 4;
      setPopupOffset({x: x, y: y});
    }
  }

  return (
    <HuiMenu as={as} {...props} ref={refMenu}>
      {buttonElement}
      <HuiTransition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        beforeEnter={onBeforeEnter}
      >
        {/* <HuiMenu.Items className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 ${bottomPos ? 'bottom-0 ' : ''} dark:bg-gray-700 dark:divide-gray-500 dark:ring-gray-600`}  ref={refPopup}> */}
        <HuiMenu.Items
          style={{
            right: `${popupOffset.x}px`,
            top: `${popupOffset.y}px`,
          }}
          className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 dark:bg-gray-700 dark:divide-gray-500 dark:ring-gray-600`} 
          ref={refPopup}
        >
          <div className="py-1">
          {actions.map((action, i) => (
            <MenuItem index={i} action={action} />
          ))}
          </div>
        </HuiMenu.Items>
      </HuiTransition>
    </HuiMenu>
  );
}