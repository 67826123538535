import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PieChart, Pie, Sector, Cell } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {
  ScatterChart,
  Scatter,
  ZAxis,
  AreaChart,
  Area,
} from 'recharts';

import * as StatisticsApi from '../../../api/statistics'

import { useFeature } from '../../../hooks/useFeature';
import { useTimer } from '../../../hooks/useTimer';

import { Responsive, WidthProvider } from "react-grid-layout";

import { Button } from "../../../components/Button";
import DateField from "../../../components/DateField";
import { Select } from "../../../components/Select";
import { Label } from "../../../components/Label";
import { Panel } from "../../../components/Panel";
import { TwPageContainer, TwPageHeaderContainer, TwPageHeaderFixed } from "../../../components/TailwindPage";
import { setMilliseconds } from 'date-fns';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function Dashboard() {
  const history = useHistory();
  const { hasFeature } = useFeature();
  const { seconds, start, pause, running, stop } = useTimer({intervalMs: (10 * 1000), initiallyRunning: false});

  // const [applicationWidgetLayouts, setApplicationWidgetLayouts] = useState([]);
  const [widgetData, setWidgetData] = useState(
    [
      {id: uuidv4(), name: "mf-sf-crdlglist", filterKey: "event", creator: (data, filter) => <><WidgetDataSmartformsCreatedDialogsList smartformsData={data} filter={filter} /></>},
      {id: uuidv4(), name: "mf-sf-crdlgcnt", creator: (data) => <><WidgetDataSmartformsCreatedDialogsCount smartformsData={data} /></>},
      {id: uuidv4(), name: "mf-test1", creator: (data) => <><WidgetDataTest1 /></>},
      {id: uuidv4(), name: "mf-test2", creator: (data) => <><WidgetDataTest2 /></>},
      {id: uuidv4(), name: "mf-test3", creator: (data) => <><WidgetDataTest3 /></>},
    ]
  );

  const [applicationCollection, setApplicationCollection] = useState([/*{ name: "Smartforms", value: "smartforms" }, { name: "Signing", value: "signing" }*/]);
  const [application, setApplication] = useState(null);
  const [statisticsData, setStatisticsData] = useState([]);
  var defaultWidgets1 = [
    // { id: uuidv4(), title: "Widget1", i: "widget1", wid: widgetData.find(x => x.name === "mf-test1").id, x: 0, y: 0, w: 2, h: 2 },
    // { id: uuidv4(), title: "Widget2", i: "widget2", wid: widgetData.find(x => x.name === "mf-test2").id, x: 2, y: 0, w: 2, h: 2 },
    // { id: uuidv4(), title: "Dialog Creation Events", i: "diaogCrc", wid: widgetData.find(x => x.name === "mf-sf-crdlgcnt").id, x: 4, y: 0, w: 2, h: 2 },
    // { id: uuidv4(), title: "Widget4", i: "widget4", wid: widgetData.find(x => x.name === "mf-test1").id, x: 0, y: 2, w: 2, h: 2 },
    // { id: uuidv4(), title: "Dialog Creation List", i: "diaogCrl", wid: widgetData.find(x => x.name === "mf-sf-crdlglist").id, x: 2, y: 2, w: 4, h: 2 }
    { id: uuidv4(), title: "Events", i: "diaogCrc", wid: widgetData.find(x => x.name === "mf-sf-crdlgcnt").id, x: 0, y: 0, w: 2, h: 2 },
    { id: uuidv4(), title: "Event Duration Plot", i: "diaogCrl", wid: widgetData.find(x => x.name === "mf-sf-crdlglist").id, x: 2, y: 0, w: 4, h: 2 }
  ];
  var defaultWidgets2 = [
    { id: uuidv4(), title: "Widget3", i: "widget3", wid: widgetData.find(x => x.name === "mf-test3").id, x: 0, y: 0, w: 2, h: 2 },
    { id: uuidv4(), title: "Widget1", i: "widget1", wid: widgetData.find(x => x.name === "mf-test1").id, x: 2, y: 2, w: 2, h: 2 },
    { id: uuidv4(), title: "Widget2", i: "widget2", wid: widgetData.find(x => x.name === "mf-test2").id, x: 4, y: 4, w: 2, h: 2 },
    { id: uuidv4(), title: "Widget4", i: "widget4", wid: widgetData.find(x => x.name === "mf-test1").id, x: 0, y: 0, w: 2, h: 2 },
  ];

  const [applicationWidgetLayouts, setApplicationWidgetLayouts] = useState([]);

  useEffect(() => {

    loadApplications();

  }, []);

  useEffect(() => {
    let idx = 0;
    let appWidgets = applicationCollection.map(app => {
      // console.log("idx", idx, "idx %= 2", idx %= 2, (idx %= 2) === 0);

      const widgets = ((idx %= 2) === 0) ? defaultWidgets1 : defaultWidgets1;
      const data = { app: app.value, widgets: widgets, layouts: [] }
      idx++;

      return data;
    });
    console.log("useEffect[applicationCollection]", "appWidgets", appWidgets);

    setApplicationWidgetLayouts(appWidgets);
  }, [applicationCollection]);

  useEffect( () => {
    loadData();
  }, [seconds]);

  useEffect(() => {
    loadData();
  }, [application]);


  useEffect(() => {
    console.log("useEffect[applicationWidgetLayouts]", "applicationWidgetLayouts", applicationWidgetLayouts);
  }, [applicationWidgetLayouts]);

  const loadApplications = async () => {
    let apps = [];
    apps = await StatisticsApi.getApplications();
    const keys = Object.keys(apps);
    const newApps = keys.map(x => {
      return {name: apps[x], value: x};
    });
    console.log("loadApplications", "apps", apps, "newApps", newApps);
    setApplicationCollection(newApps);
    setApplication(newApps[0].value);
  }

  const loadData = async () => {
    const data = await StatisticsApi.getStatistics(application);
    console.log("loadData", "application", application, "data", data);
    setStatisticsData(data);
  }

  const handleModify = (layouts, layout) => {
    console.log("handleModify", "layouts", layouts, "layout", layout);
    console.log("handleModify", "applicationWidgetLayouts", applicationWidgetLayouts, "application", application);

    // let app = appWidgets.find(a => a.app === application)
    let app = applicationWidgetLayouts.find(a => a.app === application)
    const tempArray = app.widgets;
    layouts?.map((position) => {
      tempArray[Number(position.i)].x = position.x;
      tempArray[Number(position.i)].y = position.y;
      tempArray[Number(position.i)].w = position.w;
      tempArray[Number(position.i)].h = position.h;
    });

    // let tempWidgets = [...appWidgets]
    // tempWidgets[application] = { ...application, ['widgets']: tempArray, ['layouts']: layouts };
    // appWidgets = tempWidgets;
    setApplicationWidgetLayouts(prev => {
      prev[application] = { ...application, ['widgets']: tempArray, ['layouts']: layouts }
      return prev;
    });
  };

  const handleDelete = (id) => {
    alert(id);
    // let app = applicationWidgetLayouts.find(a => a.app === application);
    // const tempArray = app.widgets.slice();
    // const index = tempArray.indexOf(tempArray.find((data) => data.id === id));
    // tempArray.splice(index, 1);
    // setApplicationWidgetLayouts(prev => {
    //   prev[application] = { ...application, ['widgets']: tempArray }
    //   return prev;
    // });
  }

  return (
    <>
      <TwPageContainer>
        <TwPageHeaderContainer>
          <TwPageHeaderFixed title="Interact Statistics" />
        </TwPageHeaderContainer>

        <div className='mb-8'>
          <div className='grid grid-cols-3 gap-2'>
            <Select label='Application' options={applicationCollection} value={application} onChange={(v) => setApplication(v.target.value)} />
            {/* <div className='space-y-1 sm:space-y-0 sm:py-1 sm:grid sm:grid-cols-1 sm:gap-3"'>
              <div>
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 dark:text-gray-300"
                >
                  From Date
                </label>
              </div>
              <DateField />
            </div>
            <div className='space-y-1 sm:space-y-0 sm:py-1 sm:grid sm:grid-cols-1 sm:gap-3"'>
              <div>
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 dark:text-gray-300"
                >
                  To Date
                </label>
              </div>
              <DateField />
            </div> */}
          </div>
        </div>

        <ResponsiveReactGridLayout
          onLayoutChange={handleModify}
          draggableHandle=".dragMe"
          verticalCompact={true}
          layout={applicationWidgetLayouts.find(a => a.app === application)?.layouts}
          // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          preventCollision={false}
          // cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }}
          cols={{ lg: 6, md: 6, sm: 4, xs: 2, xxs: 1 }}
          autoSize={true}
          isResizable={true}
          // resizeHandles='s'
          margin={{
            lg: [10, 10],
            md: [10, 10],
            sm: [10, 10],
            xs: [10, 10],
            xxs: [10, 10],
          }}
        >
          {/* {appWidgets.find(a => a.app === application)?.widgets?.map((widget, index) => { */}
          {applicationWidgetLayouts.find(a => a.app === application)?.widgets?.map((widget, index) => {
            return (
              <div
                widget={widget}
                key={index}
                data-grid={{
                  x: widget?.x,
                  y: widget?.y,
                  w: widget?.w,
                  h: widget?.h,
                  i: widget.i,
                  minW: 2,
                  maxW: Infinity,
                  minH: 2,
                  maxH: Infinity,
                  isDraggable: true,
                  isResizable: true,
                }}
              >
                <WidgetFrame widget={widget} statisticsData={statisticsData} widgetData={widgetData} onDelete={handleDelete} />
              </div>
            );
          })}
        </ResponsiveReactGridLayout>

      </TwPageContainer>
    </>
  );
}

const WidgetFrame = ({ children, widget, onDelete: onClose, statisticsData, widgetData, ...props }) => {

  //console.log("WidgetFrame", "children", children, "onClose", onClose);

  const [filter, setFilter] = useState(null);

  let filterOptions = null;
  const wd = widgetData.find(x => x.id === widget.wid);
  if (wd.filterKey)
  {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }
    const filterkeys = statisticsData.map(x => x[wd.filterKey]).filter(distinct);
    if (statisticsData && statisticsData.length > 0)
      console.log("WidgetFrame", "filterkeys", filterkeys, statisticsData[0], statisticsData[0][wd.filterKey]);
    filterOptions = filterkeys.map(x => { return {name: x, value: x}});
  }


  console.log("WidgetFrame", "wd", wd, "widget", widget, "filterOptions", filterOptions, "filter", filter);

  return (
    <div className='h-full w-full' {...props}>
      <div className={`h-full w-full flex bg-white rounded-lg px-4 pt-2 pb-4 text-left overflow-hidden shadow-xl ring-black bg-white dark:ring-gray-700 dark:bg-gray-900`}>
        <div className='w-full h-full'>
          {
            widget.title &&
            <div div className="pb-4 heading">
              <div className="flex items-center justify-between flex w-full">
              <div className='flex-1'>
                <Label text={widget.title} additionalClass='text-lg  dragMe' />
                <div className='flex-1' />
                </div>
                {filterOptions && (
                  <div className='mr-4'>
                  <Select options={filterOptions} value={filter} onChange={(v) => setFilter(v.target.value) } />
                  </div>
                )}
                <Button
                  variant={Button.variants.secondary}
                  size={Button.sizes.sm}
                  onClick={() => onClose(widget.id)}
                >
                  X
                </Button>
              </div>
            </div>
          }
          <div className='w-full h-full content-center justify-center'>
            {
              <>
                {!filterOptions && (wd.creator(statisticsData))}
                {filterOptions && (wd.creator(statisticsData, filter || (filterOptions && filterOptions.length > 0 && filterOptions[0].value)))}
            </>
            }
          </div>
          {/* Need some sort of button or else the modal throws 'There are no focusable elements inside the <FocusTrap />' for some reason */}
          {/* <button style={{ float: 'right' }} /> */}
        </div>
      </div>
    </div>
  );
}

const WidgetDataTest1 = () => {
  const data01 = [
    { x: 10, y: 30 },
    { x: 30, y: 200 },
    { x: 45, y: 100 },
    { x: 50, y: 400 },
    { x: 70, y: 150 },
    { x: 100, y: 250 },
  ];
  const data02 = [
    { x: 30, y: 20 },
    { x: 50, y: 180 },
    { x: 75, y: 240 },
    { x: 100, y: 100 },
    { x: 120, y: 190 },
  ];

  const refDiv = useRef(null);
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (refDiv.current) {

      setHeight(refDiv.current.offsetHeight);
      setWidth(refDiv.current.offsetWidth);
    }
  }, [refDiv]);

  return (
    <>
      <div ref={refDiv} className='w-full h-full'>
        <ScatterChart
          width={width}
          height={height}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="stature" unit="cm" />
          <YAxis type="number" dataKey="y" name="weight" unit="kg" />
          <ZAxis type="number" range={[100]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Legend />
          <Scatter name="A school" data={data01} fill="#8884d8" line shape="cross" />
          <Scatter name="B school" data={data02} fill="#82ca9d" line shape="diamond" />
        </ScatterChart>
      </div>
    </>
  );
}

const WidgetDataTest2 = () => {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const refDiv = useRef(null);
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (refDiv.current) {
      setHeight(refDiv.current.offsetHeight);
      setWidth(refDiv.current.offsetWidth);
    }
  }, [refDiv]);

  return (
    <>
      <div ref={refDiv} className='w-full h-full'>
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" stackId="a" fill="#8884d8" />
          <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
          <Bar dataKey="uv" fill="#ffc658" />
        </BarChart>
      </div>
    </>
  );
}

const WidgetDataTest3 = () => {
  const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const refDiv = useRef(null);
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (refDiv.current) {
      setHeight(refDiv.current.offsetHeight);
      setWidth(refDiv.current.offsetWidth);
    }
  }, [refDiv]);

  return (
    <>
      <div ref={refDiv} className='w-full h-full'>
        <PieChart width={width} height={height} className='mx-auto'>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </>
  );
}

const WidgetDataSmartformsCreatedDialogsList = ({ smartformsData, filter }) => {

  const refDiv = useRef(null);
  const [height, setHeight] = useState(250)
  const [width, setWidth] = useState(1000);

  // const refDiv = React.useCallback(node => {
  //   if (node !== null) {
  //     console.log('Container changed', node.offsetHeight, node.offsetWidth);
  //     setHeight(node.offsetHeight);
  //     setWidth(node.offsetWidth);
  //    }
  //  }, []);

   useLayoutEffect(() => {
    console.log('WidgetDataSmartformsCreatedDialogsList changed');
    setWidth(refDiv.current.offsetWidth);
    setHeight(refDiv.current.offsetHeight - 40);
  }, []);

  console.log("WidgetDataSmartformsCreatedDialogsList", "smartformsData", smartformsData, "filter", filter);

  return (
    //
    <div ref={refDiv} className='w-full h-full'>
      {smartformsData && smartformsData.length > 0 && (
        <AreaChart
          width={width}
          height={height}
          data={smartformsData.filter(i => i.event === filter).map(item => {
            return { date: item.createdDateUtc, duration: item.durationMs };
          })}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date"  tick={false} />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="duration" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      )}
    </div>
  );
}

const WidgetDataSmartformsCreatedDialogsCount = ({ smartformsData }) => {

  const refDiv = useRef(null);
  const [height, setHeight] = useState(250)
  const [width, setWidth] = useState(1000);

  // const refDiv = React.useCallback(node => {
  //   if (node !== null) {
  //     console.log('Container changed', node.offsetHeight, node.offsetWidth);
  //     setHeight(node.offsetHeight);
  //     setWidth(node.offsetWidth);
  //    }
  //  }, []);

   useLayoutEffect(() => {
    console.log('WidgetDataSmartformsCreatedDialogsList changed');
    setWidth(refDiv.current.offsetWidth);
    setHeight(refDiv.current.offsetHeight - 20);
  }, []);

  console.log("WidgetDataSmartformsCreatedDialogsCount", "smartformsData", smartformsData);

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }
  const keys = smartformsData.map(x => x.event).filter(distinct);

  console.log("WidgetDataSmartformsCreatedDialogsCount", "keys", keys);

  var data = keys.map(x => {
    return {name: x, value: smartformsData.filter(d => d.event === x).length};
  });

  console.log("WidgetDataSmartformsCreatedDialogsCount", "data", data);

  return (
    //
    <div ref={refDiv} className='w-full h-full'>
      <BarChart width={width} height={height} data={data}>
        <XAxis dataKey="name" tick={false} />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
  </div>
  );
}
