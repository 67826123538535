import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { featureFlags} from '../../utils/features.js'

import { TailwindUiContext, OidcRoutesContext, TooltipContext, DebugContext } from '../../contexts';
import { changeCompany } from '../../utils/oidcUtilities';
import './styles.scss';

import { TwNavBar } from "../../components/TailwinNavBar";

import { useAutoDarkSide, ClearDarkSideSetting, isDarkSideAutoMode, GetDarkSideAutoModeTheme } from "../../hooks/useDarkSide";
import { tailwindUiSettings } from '../../tailwindUiSettings.js';

import Tooltip from 'react-tooltip'

export default function AdminLayout({ nav, subMenuNav, children }) {
  const [colorTheme, setTheme, autoTheme, setAutoTheme] = useAutoDarkSide();

  const { environment } = useParams();

  const debugContext = useContext(DebugContext);

  // const { classNames } = useClassNames();
  const { logout } = useReactOidc();
  const oidcRoutesContext = useContext(OidcRoutesContext);
  
  const changeDarkMode = async () =>
  {
    setAutoTheme(false);
    setTheme(colorTheme);
  };
  const clearDarkMode = async () =>
  {
    ClearDarkSideSetting();
    setAutoTheme(isDarkSideAutoMode());
    setTheme(GetDarkSideAutoModeTheme());
  }

  const navigation = [
    // Home
    {
      id: uuidv4(),
      name: 'Dashboard',
      route: `/`,
      current: nav === 'home',
      featureEnabled: true
    },
  ];

  const userNavigation = [
    {
      id: uuidv4(),
      name: 'Toggle DarkMode',
      onClick: changeDarkMode
    },
    !autoTheme 
      ? {
        id: uuidv4(),
        name: 'Reset darkmode settings',
        onClick: clearDarkMode 
      } 
      : null,
    {
      id: uuidv4(),
      name: 'Change Company',
      onClick: changeCompany
    },
    {
      id: uuidv4(),
      name: 'Sign out',
      onClick: logout
    }
  ];

  // <div className="relative min-h-screen flex flex-col bg-white dark:bg-gray-900">

  return (
    <TailwindUiContext.Provider value={tailwindUiSettings}>
      <div className="relative flex flex-col h-screen bg-white dark:bg-gray-900">
        <TwNavBar navigation={navigation} userNavigation={userNavigation} helpUrl="https://mfdoc.blob.core.windows.net/mfdocs/Global.html" />

        <main className="main-container bg-brand-background z-0 focus:outline-none h-full overflow-auto dark:bg-gray-800">
          <div className={`max-w-screen-3xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8`}>
            <div className="flex flex-col pb-10 h-full">
              <div className={`max-w-screen-3xl h-full`}>
                <SetTooltipContext>
                  {children}
                </SetTooltipContext>
              </div>
            </div>
          </div>
        </main>
      </div>
    </TailwindUiContext.Provider>
  );
}

//#region TooltipContext

export const SetTooltipContext = ({ children }) => {
  const [ tooltipElements, setTooltipElements ] = useState([]);
  
  const addTooltipElementWithId = (
    id,
    getContent,
    place="top",
    type="dark",
    effect="float",
    haspopup='true',
    className='z-50'
    ) => {
      if (!id || !getContent)
        return;
      const elemTmp = tooltipElements.find(x => x.id === id);
      if (elemTmp)
        return;

      const elem = {
        id: id,
        element:
        <>
          <Tooltip id={id} place={place} type={type} effect={effect} aria-haspopup={haspopup} className={className}
            getContent={(dataTip) => {
              return getContent(dataTip);
            }}
          />
        </>
      };

      setTooltipElements(prev => {
        return [...prev, elem];
      });
      tooltipElements.push(elem);
  };

    
  const addTooltipElementWithAnchor = (
    anchor,
    getContent,
    place="top",
    type="dark",
    effect="float",
    haspopup='true',
    className='z-50'
    ) => {
      if (!anchor || !getContent)
        return;
      const elemTmp = tooltipElements.find(x => x.id === anchor);
      if (elemTmp)
        return;

      const elem = {
        id: anchor,
        element:
        <>
          <Tooltip anchorSelect={anchor} place={place} type={type} effect={effect} aria-haspopup={haspopup} className={className}
            getContent={(dataTip) => {
              return getContent(dataTip);
            }}
          />
        </>
      };

      setTooltipElements(prev => {
        return [...prev, elem];
      });
      tooltipElements.push(elem);
  };

  const elements = tooltipElements?.map(x => x.element);

  return (
    <TooltipContext.Provider
      value={{
        tooltipElements,
        addTooltipElementWithId,
        addTooltipElementWithAnchor
      }}
    >
      {children}
      {elements && elements.map((item) => (
        <>{item}</>
      ))}
    </TooltipContext.Provider>
  );
}

//#endregion
