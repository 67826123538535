import { Disclosure, /*Menu,*/ Popover, Transition } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';
import { MenuIcon, UserIcon, XIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { ViewGridIcon } from '@heroicons/react/outline';
import { useClassNames, useWhiteLabel } from '@metaforcelabs/metaforce-core';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton } from '../../components/Menu';

import { OidcRoutesContext, TailwindUiContext } from '../../contexts';
import { config } from '../../utils/config';

const TYPES = {
  fullwidth: 'fullwidth',
  maxWidth2x1: 'maxWidth2x1'
};
const TwUiNavBarSettings = (type, showUser, useDocsIcon) => {
  return {
    type: type,
    showUser: showUser || true,
    useDocsIcon: useDocsIcon || true
  };
}
TwUiNavBarSettings.types = TYPES;

export { TwUiNavBarSettings }

const UserNavLink = ({item, active}) => {
  const { classNames } = useClassNames();

  return (
    <a
      id={item.id}
      key={item.id}
      onClick={e => {
        item.onClick?.call();
      }}
      href={item.href}
      className={classNames(
        active ? 'bg-gray-100 dark:bg-gray-900' : '',
        'block px-4 py-2 text-sm text-gray-700 cursor-pointer dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
      )}
    >
      {item.name}
    </a> 
  );
}

const MainNavLink = ({item, ...props}) => {
  const { classNames } = useClassNames();

  return (
    <Link
      id={item.id}
      key={`${item.id}MNL${uuidv4()}`}
      to={item.route}
      className={classNames(
        'block rounded-md py-1 px-3 text-sm font-medium',
        !item.icon && item.current
          ? 'bg-gray-300 text-gray-900 dark:bg-gray-900 dark:text-gray-300'
          : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
      )}
    >
      {item.icon || item.name}
    </Link>
  );
};

const SubNavLink = ({item, ...props}) => {
  const { classNames } = useClassNames();

  return (
    <Link
      id={item.id}
      key={`${item.id}SNL${uuidv4()}`}
      to={item.route}
      className={`${
        item.current
          ? 'bg-gray-300 text-gray-900 dark:bg-gray-900 dark:text-gray-300'
          : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
      } block rounded-md py-1 px-3 text-sm font-medium `}
      aria-current={item.current ? 'page' : undefined}
      >
      {item.name}
    </Link>
    );
}

export const TwNavBar = ({ navigation, userNavigation, helpUrl, renderExtendedPanel }) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const tailwindUiContext = useContext(TailwindUiContext);
  const { logoUrl } = useWhiteLabel();

  const buildSubmenuOptionSelected = navigation.find(navig => navig.current)?.children;

  const settings = tailwindUiContext?.navbar;

//#region Sub Functions

  const GridIconSendBaseUrl = () => (
    <a href={config.centerpointUiBaseUrl} rel="noreferrer" target="_blank" className={''}>
      <ViewGridIcon className="h-8 w-auto text-gray-800 hover:text-gray-600 cursor-pointer dark:text-gray-300 dark:hover:text-white" />
    </a>
  );



//#endregion

  return (
    <header className="sticky top-0 z-10 w-full bg-white shadow dark:bg-gray-700">
      {/* <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:px-8"> */}
      <div className={`${settings.type === TYPES.maxWidth2x1 ? 'max-w-screen-2xl ' : '' }mx-auto px-2 sm:px-4 lg:px-8`}>
        <Popover className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <div className="flex-shrink-0 flex items-center">
              <GridIconSendBaseUrl />
            </div>

            {logoUrl && (
              <div className="ml-6 flex items-center">
                <img src={logoUrl} alt="" className="h-8 w-auto" />
              </div>
            )}

            <nav
              aria-label="Global"
              className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
            >
              {navigation.filter(x => x.featureEnabled).map(item => (
                <MainNavLink item={item} />
              ))}
            </nav>
          </div>
          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end"></div>
          <div className="flex items-center lg:hidden">
            {/* Mobile menu button */}
            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-pink dark:text-gray-400 dark:hover:text-gray-500 dark:hover:bg-gray-800">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="block h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Transition.Root as={Fragment}>
            <div className="lg:hidden">
              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay
                  className="z-20 fixed inset-0 bg-black bg-opacity-25"
                  aria-hidden="true"
                />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-500">
                    <div className="pt-3 pb-2">
                      <div className="flex items-center justify-between px-4">
                        <GridIconSendBaseUrl />
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-pink dark:bg-gray-700 dark:text-gray-300 dark:hover:text-gray-500 dark:hover:bg-gray-900">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-3 px-2 space-y-1">
                        {navigation.filter(x => x.featureEnabled).map(item =>
                          !item.children ? (
                            <Link
                              key={`${item.name}${uuidv4()}`}
                              to={item.route}
                              className={`${
                                item.current
                                  ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-300'
                                  : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                              } block rounded-md py-1 px-3 text-sm font-medium `}
                            >
                              {item.name}
                            </Link>
                          ) : (
                            <Disclosure
                              as="div"
                              key={item.name}
                              className="space-y-1"
                              defaultOpen={item.current}
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={
                                      'text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full flex items-center px-3 pr-1 py-2 text-left rounded-md focus:outline-none cursor-pointer dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                    }
                                  >
                                    <span className="flex-1 ">{item.name}</span>
                                    <svg
                                      className={`${
                                        open ? 'text-gray-400 rotate-90 dark:text-gray-300' : 'text-gray-300 dark:text-gray-500'
                                      } "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150 dark:group-hover:text-gray-500"`}
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                    >
                                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                    </svg>
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="space-y-1">
                                    {item.children.map(subItem => (
                                      <Link
                                        key={`${subItem.name}${uuidv4()}`}
                                        to={subItem.route}
                                        className={`${
                                          subItem.current
                                            ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-300'
                                            : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                        } block rounded-md py-1 px-3 text-sm font-medium `}
                                      >
                                        {subItem.name}
                                      </Link>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )
                        )}
                      </div>
                    </div>
                    <div className="pt-4 pb-2">
                      {oidcRoutesContext?.userProfile &&
                        <div className="flex items-center px-5">
                          <div className="flex-shrink-0">
                            {oidcRoutesContext?.userProfile?.userProfileImage ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={oidcRoutesContext?.userProfile?.userProfileImage}
                                alt=""
                              />
                            ) : (
                              <UserIcon className="h-6 w-6 dark:text-gray-300" aria-hidden="true" />
                            )}
                          </div>
                          <div className="ml-3">
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                              <div>{oidcRoutesContext?.customerInfo?.name} - {oidcRoutesContext?.customerInfo?.orgNumber}</div>
                            </div>
                            {/* <div className="text-base font-medium text-gray-800 dark:text-gray-200">
                              {oidcRoutesContext?.customerInfo?.name}
                            </div>
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                              {oidcRoutesContext?.customerInfo?.orgNumber}
                            </div> */}
                          </div>
                        </div>
                      }
                      <div className="mt-3 px-2 space-y-1">
                        {userNavigation.filter(x => x != null).map(item => (
                          <UserNavLink item={item} />
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>

          <div className="hidden m:ml-6 lg:flex lg:flex-none sm:items-center text-xs">
            {/* Add panel here */}
            { renderExtendedPanel && 
              <div className='flex border-l border-r border-gray-300 dark:border-gray-600 px-4'>
                {renderExtendedPanel(2, false)}
              </div>
            }

            { helpUrl && settings.useDocsIcon &&
              <div className='mr-8'>
                <a
                  key="viewnotif"
                  target='_blank'
                  className="ml-4 bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-700 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-200"
                  href={`${helpUrl}`}
                >
                    <span className="sr-only">View notifications</span>
                    <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
              </div>
            }
            { helpUrl && !settings.useDocsIcon &&
              <div className='mr-8'>
                <a
                  key="help"
                  target='_blank'
                  className="p-2 group flex space-x-2 text-gray-500 hover:text-brand-pink text-sm font-medium cursor-pointer nav-button align-middle inline-block rounded-full dark:text-gray-300 dark:hover:text-brand-pink"
                  href={`${helpUrl}`}
                >
                  <span>Docs</span>
                  <span className='flex text-white group-hover:text-brand-pink dark:text-gray-700'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3 self-end mb-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                  </span>
                </a>
              </div>
            }
            { settings.showUser &&
              <div className="float-right mr-5 text-gray-700 dark:text-gray-300">
                <div>{oidcRoutesContext?.customerInfo?.name} - {oidcRoutesContext?.customerInfo?.orgNumber}</div>
              </div>
            }
            {/* Profile dropdown */}
            <Menu
              as="div"
              actions={userNavigation.filter(x => x != null)} 
              buttonElement=
              {
                <MenuButton className="rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink hover:bg-red-400 active:bg-red-400 nav-button">
                {/* <MenuButton className={`p-1 rounded-full text-black hover:text-white focus:outline-none hover:bg-red-400 active:bg-red-400 nav-button align-middle ${open ? "active" : ""> */}

                  <span className="sr-only">Open user menu</span>
                  {oidcRoutesContext?.userProfile?.userProfileImage ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={oidcRoutesContext?.userProfile?.userProfileImage}
                      alt=""
                    />
                  ) : (
                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </MenuButton>
              }
              className="ml-4 relative flex-shrink-0"
            />
          </div>
        </Popover>
      </div>

      {buildSubmenuOptionSelected && (
        // <div className="max-w-screen-2xl mx-auto  mx-auto px-4 sm:px-6">
        <div className={`${settings.type === TYPES.maxWidth2x1 ? 'max-w-screen-2xl ' : '' }mx-auto px-4 sm:px-6`}>
          <div className="hidden lg:flex lg:border-t lg:border-gray-200 lg:py-3 lg:dark:border-gray-500">
            <nav className="hidden lg:ml-1 lg:flex lg:justify-between " aria-label="Breadcrumb">
              <div className="hidden sm:block">
                <ol className="flex items-center space-x-4">
                  {buildSubmenuOptionSelected.map((item, index) => {
                    return (
                      <li key={item.name}>
                        <div className={` flex items-center`}>
                          {/* {index !== 0 && (
                            <svg
                              className="flex-shrink-0 h-5 w-5 text-gray-300 m-0"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                          )} */}
                          <SubNavLink item={item} />
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
}