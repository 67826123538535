
import { TwUiToggleSettings } from "./components/Toggle";
import { TwUiFormSettings } from "./components/TailwindForm";
import { TwUiNavBarSettings } from "./components/TailwinNavBar";

const fullWidth = false;
const menuFullWidth = true;
export const tailwindUiSettings = {
  fullwidth: fullWidth,
  form: TwUiFormSettings(TwUiFormSettings.types.twoColumn),
  toggle: TwUiToggleSettings(TwUiToggleSettings.types.icon, TwUiToggleSettings.alignments.right, false, false),
  navbar: TwUiNavBarSettings(menuFullWidth ? TwUiNavBarSettings.types.fullwidth : TwUiNavBarSettings.types.maxWidth2x1)
}
