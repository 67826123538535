import classNames from 'classnames';

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  normal: 'normal',
  custom: 'custom',
  icon: 'icon'
};

const SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg'
};

const Button = ({
  children,
  className,
  variant,
  size,
  id,
  disabled,
  isUppercase,
  isSubmitting,
  onClick,
  ...props
}) => {
  return (
    <>
      <button
        id={id}
        type="button"
        className={classNames(
          'inline-flex justify-center rounded-md shadow-sm font-medium',
          className,
          size === SIZES.xs &&
            'px-2 py-1 text-xs',
          size === SIZES.sm &&
            'px-2 py-1 text-sm',
          size !== SIZES.xs && size !== SIZES.sm &&
            'px-4 py-2',
          variant === VARIANTS.secondary &&
            'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm xs:text-xs dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700',
          variant === VARIANTS.primary &&
            'bg-brand-pink hover:bg-brand-pink-hover text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm xs:text-xs',
          variant === VARIANTS.normal &&
            'border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm xs:text-xs dark:border-gray-600 dark:focus:ring-gray-700 bg-white text-gray-900 hover:bg-gray-100 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-text-gray-300'.
          variant === VARIANTS.custom &&
            'border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm xs:text-xs dark:border-gray-600 dark:focus:ring-gray-700',
          variant === VARIANTS.icon && 'border-0 shadow-none',
          disabled && 'opacity-50 cursor-not-allowed',
          !size && 'text-base',
          size ? size : 'md',
          isUppercase && 'uppercase ',

        )}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {isSubmitting && (
          <svg
            className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}

        {children}
      </button>
    </>
  );
};

Button.variants = VARIANTS;
Button.sizes = SIZES;

export { Button }
