import React, { useContext } from 'react';
import { TailwindUiContext } from '../../contexts';

export const TwPageContainer = ({ children, ...props }) => {
  const tailwindUiContext = useContext(TailwindUiContext);

  return (
    <div className="w-full" {...props}>
      <div className={`${tailwindUiContext.fullwidth ? "" : "max-w-screen-3xl"} mx-auto px-4 sm:px-6 lg:px-8`}>
        {children}
      </div>
    </div>
  );
}

export const TwPageHeaderContainer = ({ children, isEnvGrpDep = true, ...props }) => {
  return (
    <div className="mb-8" {...props}>
      <div className="sm:flex sm:items-center">
        {children}
      </div>
    </div>
  );
}

export const TwPageHeader = ({ children, ...props }) => {
  return (
    <div className="sm:flex-auto" {...props}>
      {children}
    </div>
  );
}

export const TwPageHeaderFixed = ({ title, description, ...props }) => {
  return (
    <TwPageHeader {...props}>
      <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate dark:text-white">
        {title}
      </h2>
      {
        description && !Array.isArray(description) && <p className="mt-6 dark:text-gray-400">{description}</p>
      }
      {
        description && Array.isArray(description) && <div className='mt-6'>{description.map(desc => <p className="dark:text-gray-400">{desc}</p>)}</div>
      }
    </TwPageHeader>
  );
}

const buttonsVerticalPositionTypes = {
  top: 1,
  center: 2,
  bottom: 3
}

const TwPageHeaderButtons = ({ children, verticalPosition = buttonsVerticalPositionTypes.bottom, ...props }) => {
  let vposCls = "self-end";
  if (verticalPosition === buttonsVerticalPositionTypes.center)
    vposCls = "self-center";
  else if (verticalPosition === buttonsVerticalPositionTypes.top)
    vposCls = "self-start";
  return (
    <div className={`mt-4 sm:ml-16 sm:mt-0 sm:flex-none ${vposCls}`} {...props}>
      {children}
    </div>
  );
}

TwPageHeaderButtons.verticalPositionTypes = buttonsVerticalPositionTypes;

export { TwPageHeaderButtons };
