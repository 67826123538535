import { useCallback, useEffect, useState } from "react";

const interval = (delay = 0) => /** @param {() => void} callback */ (
  callback
) =>
  useEffect(() => {
    const id = setInterval(callback, delay);

    return () => clearInterval(id);
  }, [callback]);

export const useTimer = ({
  intervalMs = 1000,
  initialSeconds = 0,
  initiallyRunning = false
} = {}) => {
  const useCustom = interval(intervalMs === 0 ? 1000 : intervalMs);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(initiallyRunning);
  const tick = useCallback(
    () => {
      return (running ? setSeconds((seconds) => seconds + (intervalMs / 1000)) : undefined)
    },
    [running]
  );
  const start = () => setRunning(true);
  const pause = () => setRunning(false);
  const reset = () => setSeconds(0);
  const stop = () => {
    pause();
    reset();
  };

  useCustom(tick);

  return { pause, reset, running, seconds, start, stop };
};
