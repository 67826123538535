import { useState, useEffect } from "react";

export function isDarkSideAutoMode() {
    return !('theme' in localStorage) || localStorage.getItem('theme') === 'undefined';
}

export function GetDarkSideAutoModeTheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
}

const invertTheme = (oldTheme) => { return oldTheme === "dark" ? "light" : "dark"; };

// Use this when darkmode is set automatically and use localStorage as override
export function useAutoDarkSide() {
    const usingAuto = isDarkSideAutoMode();
    const [autoTheme, setAutoTheme] = useState(usingAuto);
    const [theme, setTheme] = useState(usingAuto ? GetDarkSideAutoModeTheme() : localStorage.theme);
    const colorTheme = invertTheme(theme);
 
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);

        if (!autoTheme)
            localStorage.setItem('theme', theme);
    }, [theme, colorTheme, autoTheme]);
 
    return [colorTheme, setTheme, autoTheme, setAutoTheme]
}

// Use this when darkmode should only rely on localStorage
export function useDarkSide() {
    const [theme, setTheme] = useState(localStorage.theme);
    const colorTheme = theme === "dark" ? "light" : "dark";
 
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);
        localStorage.setItem('theme', theme);
    }, [theme, colorTheme]);
 
    return [colorTheme, setTheme]
}

export const ClearDarkSideSetting = () => {
    localStorage.removeItem('theme');
}