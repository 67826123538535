import { get, BaseUrl, } from './api'

export const getApplications = async () => {
    return await get(`applications`);
}

export const getStatistics = async (application) => {
    return await get(`statistics/${application}`);
}

// export const getStatisticsQuery = async (application) => {
//     return await get(`statistics/${application}`);
// }
